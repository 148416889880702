import {Flex, SimpleGrid} from '@mantine/core';
import classes from './Home.module.css';
import {SignupForm} from "./index";
import {HomeWelcome} from "./HomeWelcome";

export function Home() {
    return (
        <div className={classes.wrapper}>
            <SimpleGrid cols={{base: 1, sm: 2}} spacing={50}>
                <HomeWelcome/>
                <SignupForm/>
            </SimpleGrid>
            <Flex justify="flex-end" mt="md">
                <a
                    className={classes.footerText}
                    href="https://se.ewi.tudelft.nl/ai4se/">
                    AI4SE TU Delft x JetBrains
                </a>
            </Flex>
        </div>
    );
}
