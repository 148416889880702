import {getComboboxData} from "../util/combobox";

export type JobRole = keyof typeof jobRoles;

export const jobRoles = {
    DEVELOPER_PROGRAMMER_SWE: 'Developer / Programmer / Software Engineer',
    DEVOPS_ENGINEER_INFRASTRUCTURE_DEVELOPER: 'DevOps Engineer / Infrastructure Developer',
    DBA: 'DBA',
    ARCHITECT: 'Architect',
    TESTER_QA_ENGINEER: 'Tester / QA Engineer',
    TECHNICAL_SUPPORT: 'Technical Support',
    DATA_ANALYST_DATA_ENGINEER_DATA_SCIENTIST: 'Data Analyst / Data Engineer / Data Scientist',
    BUSINESS_ANALYST: 'Business Analyst',
    TECHNICAL_WRITER: 'Technical Writer',
    TEAM_LEAD: 'Team Lead',
    SYSTEMS_ANALYST: 'Systems Analyst',
    PRODUCT_MANAGER_MARKETING_MANAGER: 'Product Manager / Marketing Manager',
    UX_UI_DESIGNER: 'UX / UI Designer',
    CIO_CEO_CTO: 'CIO / CEO / CTO',
    DEVELOPER_ADVOCATE: 'Developer Advocate',
    INSTRUCTOR_TEACHER_TUTOR: 'Instructor / Teacher / Tutor',
    INTERN_STUDENT: 'Intern / Student',
    OTHER: 'Other, please specify',
} as const;

export const jobRolesData = getComboboxData(jobRoles);
