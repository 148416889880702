import {Text, Title} from "@mantine/core";
import classes from "./HomeWelcome.module.css";

export function HomeWelcome() {
    return (
        <div>
            <Title className={classes.title}>Welcome to IALLMs!</Title>
            <Text className={classes.description} mt="sm" mb={30}>
                Thank you for your interest in our plugin and user study!
                We are excited to have you join our user study.
            </Text>
            <Text className={classes.description} mt="sm" mb={30}>
                After you have downloaded the plugin and filled out the form,
                you will be able to use the plugin in your favorite JetBrains IDE.
                For the first part of the study,
                you will be able to freely use the plugin to explore the code completion and chat features.
                For the second part of the study,
                we will invite you via email to join a smaller coding exercise,
                where you will be able to use the plugin to perform small coding tasks.
                We hope you will be able to join the second part of the study as well!
            </Text>
            <Text className={classes.description} mt="sm" mb={30}>
                If you have any questions or comments, you can always contact me at{' '}
                <a className={classes.contactLink} href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
                    {process.env.REACT_APP_CONTACT_EMAIL}
                </a>.
            </Text>
            <iframe
                className={classes.pluginFrame}
                width="384px"
                height="319px"
                src="https://plugins.jetbrains.com/embeddable/card/24439"
            />
        </div>
    )
}
