import {getComboboxData} from "../util/combobox";

export type CodingExperience = keyof typeof codingExperiences;

export const codingExperiences = {
    LESS_THAN_ONE_YEAR: 'Less than 1 year',
    ONE_TO_TWO_YEARS: '1-2 years',
    THREE_TO_FIVE_YEARS: '3-5 years',
    SEVEN_TO_TEN_YEARS: '7-10 years',
    ELEVEN_TO_SIXTEEN_YEARS: '11-16 years',
    SIXTEEN_YEARS_OR_MORE: '16 years or more',
    NO_PROFESSIONAL_CODING_EXPERIENCE: "I don't have any professional coding experience",
}

export const codingExperiencesData = getComboboxData(codingExperiences);
