import {getComboboxData} from "../util/combobox";

export type EmploymentStatus = keyof typeof employmentStatuses;

export const employmentStatuses = {
    FULLY_EMPLOYED: 'Fully employed by a company or organization',
    PARTIALLY_EMPLOYED: 'Partially employed by a company or organization',
    SELF_EMPLOYED: 'Self-employed (earning income directly from your own business, trade, or profession)',
    FREELANCER: 'Freelancer (pursuing a profession without a long-term commitment to any one employer)',
    WORKING_STUDENT: 'Working student',
    STUDENT: 'Student',
    RETIRED: 'Retired',
    OTHER: 'Other, please specify',
}

export const employmentStatusesData = getComboboxData(employmentStatuses);
