import {useForm} from "@mantine/form";
import {
    Button,
    Checkbox,
    Group,
    MultiSelect,
    Select,
    SimpleGrid,
    Space,
    TextInput,
    Title,
    Stack,
} from "@mantine/core";
import classes from "./SignupForm.module.css";
import {JobRole, jobRolesData} from "../data/job-roles";
import {useLocalState} from "../hooks/use-local-state";
import {EmploymentStatus, employmentStatusesData} from "../data/employment-status";
import {codingExperiencesData} from "../data/coding-experience";
import {useState} from "react";
import {Alert} from '@mantine/core';
import {IconExclamationCircle, IconCircleCheck} from '@tabler/icons-react';

export type SignupResult = {
    type: 'success';
} | {
    type: 'error';
    message: string;
};


export function SignupForm() {
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            email: '',
            job_roles: [] as JobRole[],
            job_role_other: '',
            employment_status: '',
            employment_status_other: '',
            coding_experience: '',
            terms_of_service: false,
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            job_roles: (value) => (value.length === 0 ? 'Please pick at least one profession' : null),
            job_role_other: (value, values) => {
                return ((!values.job_roles.includes('OTHER') || value?.trim()) ? null : 'Please specify your job role');
            },
            employment_status: (value) => (value ? null : 'Please pick an employment status'),
            employment_status_other: (value, values) => {
                return ((!values.employment_status.includes('OTHER') || value?.trim()) ? null : 'Please specify your employment status');
            },
            coding_experience: (value) => (value?.trim() ? null : 'Please pick a coding experience'),
            terms_of_service: (value) => (value ? null : 'Please agree to the terms of service to be able to participate'),
        },
    });

    const [jobRoles, setJobRoles] = useLocalState<JobRole[]>(form.values.job_roles);
    const [employmentStatus, setEmploymentStatus] = useLocalState<EmploymentStatus | string>(form.values.employment_status);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<SignupResult>();

    const onSubmit = form.onSubmit(async (values) => {
        setLoading(true);
        try {
            const res = await fetch('/api/signups/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(values),
            });

            let data: any;
            if (res.headers.get('content-type') === 'application/json') {
                data = await res.json();
            } else {
                data = await res.text();
            }

            if (res.status === 201) {
                form.reset();
                setResult({type: 'success'});
            } else {
                let message: string;
                if (typeof data?.error === 'string') {
                    message = data.error;
                } else if (typeof data === 'string') {
                    message = data;
                } else if (data == null) {
                    message = 'An unknown error occurred';
                } else {
                    message = JSON.stringify(data);
                }
                setResult({type: 'error', message});
            }
        } catch (e) {
            setResult({type: 'error', message: e instanceof Error ? e.message : 'An unknown error occurred'});
            console.error(e);
        } finally {
            setLoading(false);
        }
    });

    return (
        <div>
            <form className={classes.form} onSubmit={onSubmit}>
                <Title order={2}>Sign up for our study</Title>
                <Space h="xs"/>
                <SimpleGrid cols={1} verticalSpacing="xs">
                    <TextInput
                        withAsterisk
                        label="Email"
                        placeholder="your@email.com"
                        key={form.key('email')}
                        {...form.getInputProps('email')}
                    />

                    <MultiSelect
                        withAsterisk
                        label="Which of the following best describes your job role?"
                        placeholder="Pick job role(s)"
                        data={jobRolesData}
                        key={form.key('job_roles')}
                        {...form.getInputProps('job_roles')}
                        onChange={(values) => {
                            const jobRoles = values as JobRole[];
                            form.setFieldValue('job_roles', jobRoles);
                            setJobRoles(jobRoles);
                        }}
                    />
                    {jobRoles.includes('OTHER') && (
                        <TextInput
                            withAsterisk
                            label="Please specify your job role"
                            placeholder="Other, please specify"
                            key={form.key('job_role_other')}
                            {...form.getInputProps('job_role_other')}
                        />
                    )}

                    <Select
                        withAsterisk
                        label="What is your employment status?"
                        placeholder="Pick employment status"
                        data={employmentStatusesData}
                        allowDeselect={false}
                        key={form.key('employment_status')}
                        {...form.getInputProps('employment_status')}
                        onChange={(value) => {
                            const employmentStatus = value as EmploymentStatus;
                            form.setFieldValue('employment_status', employmentStatus);
                            setEmploymentStatus(employmentStatus);
                        }}
                    />
                    {employmentStatus === 'OTHER' && (
                        <TextInput
                            withAsterisk
                            label="Please specify your employment status"
                            placeholder="Other, please specify"
                            key={form.key('employment_status_other')}
                            {...form.getInputProps('employment_status_other')}
                        />
                    )}

                    <Select
                        withAsterisk
                        label="How many full years of professional coding experience do you have?"
                        placeholder="Pick coding experience"
                        data={codingExperiencesData}
                        allowDeselect={false}
                        key={form.key('coding_experience')}
                        {...form.getInputProps('coding_experience')}
                    />
                </SimpleGrid>

                <Checkbox
                    mt="md"
                    label="I agree that my data will be used for research"
                    description="We will only use your code completions and chat messages to perform the user study, and will not share your data with any other third party."
                    key={form.key('terms_of_service')}
                    {...form.getInputProps('terms_of_service', {type: 'checkbox'})}
                />

                <Group justify="flex-end" mt="md">
                    {!result && <Button type="submit" loading={loading}>Submit</Button>}
                    {result?.type === 'error' && (
                        <Alert
                            className={classes.alert}
                            title="Error"
                            variant="light"
                            color="red"
                            withCloseButton
                            onClose={() => setResult(undefined)}
                            icon={<IconExclamationCircle/>}
                        >
                            <Stack align="stretch" justify="flex-start" gap="xs">
                                <span>{result.message}</span>
                                <span>
                                If you believe this is in error, please contact us at{' '}
                                    <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
                                    {process.env.REACT_APP_CONTACT_EMAIL}
                                </a>
                            </span>
                            </Stack>
                        </Alert>
                    )}
                    {result?.type === 'success' && (
                        <Alert
                            className={classes.alert}
                            title="Thank you for registering!"
                            variant="light"
                            color="blue"
                            withCloseButton
                            onClose={() => setResult(undefined)}
                            icon={<IconCircleCheck/>}
                        >
                        <span>
                            Please check your email for your credentials.
                            You will need these to log in inside the plugin settings.
                        </span>
                        </Alert>
                    )}
                </Group>
            </form>
        </div>
    );
}
